import React from "react";
import "./style.css";

const RoadMap = () => {
  return (
    <section class="design-section">
      <div class="timeline">
        <div class="timeline-empty"></div>
        <div class="timeline-middle">
          <div class="timeline-circle"> </div>
        </div>
        <div class="timeline-component timeline-content">
          <h3 className="heading-timeline">Phase 1: Foundation (Q1 - Q2 2024)</h3>
          <h1 style={{ fontSize: "20px", fontWeight: "bold", marginTop:'20px' }}>
            Token Launch & Smart Contract Development
          </h1>
          <ul className="para-timeline">
            <li>
              Launch MILEON token on Binance Smart Chain with robust security features.
            </li>
          </ul>
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
            Community Building & Partnerships
          </h1>
          <ul className="para-timeline">
            <li>
              Engage with early supporters and establish strategic partnerships to expand our reach.
            </li>
          </ul>
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
          Initial Exchange Listings
          </h1>
          <ul className="para-timeline">
            <li>
            Secure listings on major decentralized and centralized exchanges to enhance liquidity.
            </li>
          </ul>
        </div>
        <div class="timeline-component timeline-content">
          <h3 className="heading-timeline">Phase 2: Expansion (Q3 - Q4 2024)</h3>
          <h1 style={{ fontSize: "20px", fontWeight: "bold", marginTop:'20px'  }}>
          DeFi Product Development
          </h1>
          <ul className="para-timeline">
            <li>
            Introduce decentralized finance (DeFi) applications powered by MILEON, including staking, yield farming, and lending.
            </li>
          </ul>
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
          Mobile Wallet Integration
          </h1>
          <ul className="para-timeline">
            <li>
            Develop user-friendly mobile wallets to facilitate easy MILEON transactions and storage.
            </li>
          </ul>
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
          Cross-Chain Compatibility
          </h1>
          <ul className="para-timeline">
            <li>
            Explore interoperability with other blockchains to expand MILEON's utility and accessibility.
            </li>
          </ul>
        </div>

        <div class="timeline-middle">
          <div class="timeline-circle"> </div>
        </div>

        <div class="timeline-empty"> </div>
        <div class="timeline-empty"></div>

        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>

        <div class=" timeline-component timeline-content">
          <h3 className="heading-timeline">Phase 3: Adoption (2025 Onward)</h3>
          <h1 style={{ fontSize: "20px", fontWeight: "bold", marginTop:'20px'  }}>
          Global Outreach & Education
          </h1>
          <ul className="para-timeline">
            <li>
            Promote MILEON adoption worldwide through educational initiatives and awareness campaigns.
            </li>
          </ul>
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
          Partnerships with Institutions
          </h1>
          <ul className="para-timeline">
            <li>
            Collaborate with financial institutions and enterprises to integrate MILEON into mainstream finance.
            </li>
          </ul>
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
          Continuous Innovation & Upgrades
          </h1>
          <ul className="para-timeline">
            <li>
            Stay ahead of industry trends by continuously upgrading MILEON's technology and offerings.
            </li>
          </ul>
        </div>
        

        {/* <h1>Join the MILEON Revolution</h1> */}
        {/* <div class=" timeline-component timeline-content">
          <h3 className="heading-timeline">November 2023 to January 2024</h3>
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
            Community Expansion:
          </h1>
          <ul className="para-timeline">
            <li>Scale the community to 50,000 investors.</li>
            <li>
              Begin distributing the xGPT magazine to the expanded community.
            </li>
          </ul>
        </div>
        <div class="timeline-middle">
          <div class="timeline-circle"></div>
        </div>
        <div class="timeline-empty"> </div>
        <div class="timeline-empty"> </div>
        <div class="timeline-middle">
          <div class="timeline-circle"> </div>
        </div>
        <div class="timeline-component timeline-content">
          <h3 className="heading-timeline">January to April 2024</h3>
          <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>ICO Launch:</h1>
          <ul className="para-timeline">
            <li>
              Launch the ICO, marking a pivotal milestone in the project's
              timeline.
            </li>
            <li>
              Launch an AI application tailored for market research, potentially
              revolutionizing data analysis and insights.
            </li>
          </ul>
        </div> */}
      </div>
    </section>
  );
};

export default RoadMap;
